export const PHAN_DISH = [
  { name: 'Siu Mai', price: '65 kr', image: 'phan-101' },
  { name: 'Wonton', price: '65 kr', image: 'phan-102' },
  { name: 'Stegt wonton', price: '65 kr', image: 'phan-103' },
  { name: 'Kylling dumplings', price: '65 kr', image: 'phan-103' },
  { name: 'Ha gaw', price: '65 kr', image: 'phan-104' },
  { name: 'Hot Wings', price: '65 kr', image: 'phan-105' },
  { name: 'Sesam boller', price: '35 kr', image: 'phan-107' },
  // {
  //   name: 'Bao Buns - Charsiu marineret svine kød',
  //   price: '85 kr',
  //   image: 'phan-1061',
  //   description: '',
  // },
  // {
  //   name: 'Bao Buns - Crispy chicken\n ',
  //   price: '85 kr',
  //   image: 'phan-1062',
  //   description: '',
  // },
  // {
  //   name: 'Bao Buns - Pull beef',
  //   price: '85 kr',
  //   image: 'phan-1063',
  //   description: '',
  // },
  // {
  //   name: 'Bao Buns - Tofu og vegansk mayo',
  //   price: '85 kr',
  //   image: 'phan-1064',
  //   description: '',
  // },
];

export const PHAN_SOUP = [
  {
    name: 'Wontonsuppe',
    description:
      'Hønsekødssuppe, ramen-nudler, char siu marineret svinekød, wontons med svinefars og rejer.',
    price: '115 kr',
    image: 'phan-1004',
  },
];

export const PHAN_MENU = [
  {
    name: 'Bao Combo',
    price: '135 kr',
    description: 'Valgfri bao og valgfri Sidedish',
    image: 'phan-1061',
  },
  {
    name: 'Snack plate dele',
    price: '170 kr',
    description:
      '2 stk bao 12 stk dumpling (hotwings er ikke med i share plate)',
    image: 'phan-109',
  },
  {
    name: 'Share plate til 2 personer',
    price: '280 kr',
    description:
      '2 stk bao, 20 stk dumplings og 2 stk sesam boller (hotwings er ikke med i share plate)',
    image: 'phan-109',
  },
  {
    name: 'Share plate til 3 personer',
    price: '410 kr',
    description:
      '3 stk bao, 30 stk dumplings  og 3 sesam boller (hotwings er ikke med i share plate)',
    image: 'phan-109',
  },
  {
    name: 'Tilkøb fries m. dip',
    price: ' 35 kr',
    description: '(Vælg mellem Estragon aioli, trøffel mayo eller chilli mayo)',
    image: 'phan-10122',
  },
];

// export const PHAN_SIDEDISH = [
//   {
//     name: 'Dip',
//     price: '10 kr',
//     description: '(Vælg mellem Estragon aioli, trøffel mayo eller chilli mayo)',
//     image: 'phan-10122',
//   },
//   {
//     name: 'Sweet potato fries',
//     price: ' 39 kr',
//     image: 'phan-10121',
//   },
// ];

// Foodora
export const PHAN_POPULAR = [
  {
    name: 'Nudelsuppe (Phở Bò)',
    description:
      'Risnudelsuppe med oksekød. Serveres med sød basilikum, koriander, bønnespirer, rødløg, frisk chili og lime',
    price: '120 kr.',
    image:
      'https://images.deliveryhero.io/image/fd-dk/Products/5519981.jpg?width=600&height=600',
  },
  {
    name: 'Crispy Chicken Bao, 2 Stk.',
    description:
      'Med syltede gulerødder, Kinaradiser, agurk, chili, koriander, soya og hjemmelavet char siu mayo',
    price: '85 kr.',
    image:
      'https://images.deliveryhero.io/image/fd-dk/Products/5519976.jpg?width=600&height=600',
  },
  {
    name: 'Bao Combo',
    description: '2 stk. bao med sweet potato fries og dip',
    price: '130 kr.',
    image:
      'https://images.deliveryhero.io/image/fd-dk/Products/5519980.jpg?width=600&height=600',
  },
  {
    name: 'Ribeye Salat (Bò Xào Xa Lát)',
    description:
      'Lynstegt ribeye med grøntsager, urter, blødkogt æg og Asiatisk chimichurri',
    price: '110 kr.',
    image:
      'https://images.deliveryhero.io/image/fd-dk/Products/5519984.jpg?width=600&height=600',
  },
  {
    name: 'Char Siu Bao, 2 Stk.',
    description:
      'Med char siu svinekød, syltede gulerødder, Kinaradiser, agurk, chili, koriander, soya og hjemmelavet char siu mayo',
    price: '85 kr.',
    image:
      'https://images.deliveryhero.io/image/fd-dk/Products/5519975.jpg?width=600&height=600',
  },
];

export const PHAN_MAIN = [
  {
    name: 'Nudelsalat (Bún Thịt Nướng)',
    description: 'Nudelsalat med fiskesovs, grøntsager og urter',
    price: '110 kr.',
    image: 'phan-10206',
  },
  {
    name: 'Ribeye Salat (Bò Xào Xa Lát)',
    description:
      'Lynstegt ribeye med grøntsager, urter, blødkogt æg og Asiatisk chimichurri',
    price: '110 kr.',
    image: 'phan-10207',
  },
  {
    name: 'Aarhus - Nudelsuppe (Phở Bò)',
    description:
      'Risnudelsuppe med oksekød. Serveres med sød basilikum, koriander, bønnespirer, rødløg, frisk chili og lime',
    price: '120 kr.',
    image: 'phan-10204',
  },
  {
    name: 'Aarhus - Nudelsuppe (Phở Đặc Biệt)',
    description:
      'Signatur pho med lynstegt ribeye. Serveres med sød basilikum, koriander, bønnespirer, rødløg, frisk chili og lime',
    price: '150 kr.',
    image: 'phan-10205',
  },
];

export const PHAN_BAOS = [
  {
    name: 'Char Siu Bao, 2 Stk.',
    description:
      'Med char siu svinekød, syltede gulerødder, Kinaradiser, agurk, chili, koriander, soya og hjemmelavet char siu mayo',
    price: '85 kr.',
    image: 'phan-1061',
  },
  {
    name: 'Crispy Chicken Bao, 2 Stk.',
    description:
      'Med syltede gulerødder, Kinaradiser, agurk, chili, koriander, soya og hjemmelavet char siu mayo',
    price: '85 kr.',
    image: 'phan-1062',
  },
  {
    name: 'Vegan Tofu 2 Bao (2 stk.)',
    description:
      'Med syltede gulerødder, Kinaradiser, agurk, chili, koriander, soya og vegansk mayo',
    price: '85 kr.',
    image: 'phan-1064',
  },
  {
    name: 'Pulled Beef Bao, 2 Stk.',
    description:
      'Med sprød salat, syltede rødløg, drueagurker, chili, koriander, soya og hjemmelavet char siu mayo',
    price: '85 kr.',
    image: 'phan-1063',
  },
  {
    name: 'Tempura Rejer Bao, 2 Stk.',
    description:
      'Med sprød salat, kimchi, syltede drueagurker, chili, koriander, soya og hjemmelavet char siu mayo',
    price: '85 kr.',
    image: 'phan-10208',
  },
  {
    name: 'Bao Combo',
    description: '2 stk. bao med sweet potato fries og dip',
    price: '130 kr.',
    image: 'phan-10209',
  },
];

export const PHAN_SIDES = [
  {
    name: 'Sweet Potato Fries',
    description: 'Sweet potato fries',
    price: 'fra 39 kr.',
    image: 'phan-10121',
  },
  // {
  //   name: 'Sommerruller (Gỏi Cuốn)',
  //   description: 'Med rispapir, grøntsager og urter',
  //   price: '65 kr.',
  //   image: 'phan-10203',
  // },
  {
    name: 'Hot Wings, 5 Stk.',
    description: 'Marineret i sød og stærk chilisovs',
    price: '65 kr.',
    image: 'phan-105',
  },
];

export const PHAN_DIPS = [
  {
    name: 'Trøffelmayo',
    description: 'Trøffelmayo',
    price: '10 kr.',
    image: 'phan-10200',
  },
  {
    name: 'Estragonaioli',
    description: 'Estragonaioli',
    price: '10 kr.',
    image: 'phan-10201',
  },
  {
    name: 'Chilimayo',
    description: 'Chilimayo',
    price: '10 kr.',
    image: 'phan-10202',
  },
];
