import React from 'react';
import Img from 'gatsby-image';
import { v4 } from 'uuid';

export const Food = ({ source, data }) => {
  return (
    <div className="mb-12">
      {source ? (
        <Img
          fluid={source}
          className="ml-auto w-full rounded mb-4 bg-contain h-[22rem]"
        />
      ) : (
        <div className="ml-auto w-full rounded mb-4 bg-gray-200 opacity-50 h-[22rem]"></div>
      )}
      <div>
        <div className="font-semibold text-xl mb-1 text-gray-800">
          {data.name}
        </div>
        <div className="text-sm text-gray-600">
          {data.description
            ? data.description.split('\n').map((t) => {
                return <div key={v4()}>{t}</div>;
              })
            : ''}
        </div>
        <div className="flex">
          <span className="ml-auto">{data.price}</span>
        </div>
      </div>
    </div>
  );
};
