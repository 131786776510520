import React from 'react';

import Talk from 'assets/svg/talk.svg';
import Truck from 'assets/svg/truck.svg';
import Walking from 'assets/svg/walking.svg';

export const StepOrder = ({ phone = '26563581' }) => {
  return (
    <div className="py-16">
      <div className="sm:grid grid-cols-3 gap-12 text-center sm:text-3xl font-bold mb-4 space-y-8 sm:space-y-0">
        <div>
          <Talk className="h-8 sm:h-12 fill-current text-gray-800 mx-auto mb-4" />
          <div className="text-gray-800">1. Ring</div>
          <div className="text-lg font-normal">
            <a href={`tel:${phone}`}>{phone}</a>
          </div>
        </div>
        <div>
          <Truck className="h-8 sm:h-12 fill-current text-gray-800 mx-auto mb-4" />
          <div className="text-gray-800">2. Bestil</div>
          <div className="text-lg font-normal">Take away mad</div>
        </div>
        <div>
          <Walking className="h-8 sm:h-12 fill-current text-gray-800 mx-auto mb-4" />
          <div className="text-gray-800">3. Afhent</div>
          <div className="text-lg font-normal">
            <a
              href="https://www.google.com/maps/dir//Storms+Pakhus+%E2%80%93+Odense+Street+Food/data=!4m6!4m5!1m1!4e2!1m2!1m1!1s0x464cdffda27b384d:0xf9fb82e3bb6025d3?sa=X&ved=2ahUKEwidm_nn3d_sAhVI3aQKHQvMB8QQ9RcwDnoECCEQBQ"
              target="_blank"
            >
              Storms Pakhus
            </a>
            <div>Lerchesgade 4, 5000 Odense C</div>
          </div>
        </div>
      </div>
    </div>
  );
};
