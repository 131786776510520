import { graphql, useStaticQuery } from 'gatsby';
import React from 'react';

import { Food } from 'components/Food';
import { FoodList } from 'components/FoodList';
import { InfoBox } from 'components/InfoBox';
import SEO from 'components/SEO';
import Layout from 'components/layout';
import { PHAN_DISH, PHAN_MENU, PHAN_SIDES } from '../data/food';

const TakeAwayPage = () => {
  const data = useStaticQuery(graphql`
    query {
      mobileImage: file(relativePath: { eq: "venue-01.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 820, quality: 70) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      desktopImage: file(relativePath: { eq: "venue-01.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 820, quality: 70) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      food: allFile(filter: { name: { regex: "/(phan-)/" } }) {
        edges {
          node {
            childImageSharp {
              fluid(maxWidth: 400, quality: 80) {
                ...GatsbyImageSharpFluid
              }
            }
            name
          }
        }
      }
    }
  `);

  const sources = [
    data.mobileImage.childImageSharp.fluid,
    {
      ...data.desktopImage.childImageSharp.fluid,
      media: `(min-width: 768px)`,
    },
  ];

  return (
    <Layout>
      <SEO title="Take Away" />
      <div className="max-w-screen-xl px-6 sm:mx-auto py-4 md:py-8">
        <InfoBox />

        <div className="md:flex mb-12">
          <div className="mb-16 md:mb-0 md:pr-8 md:w-6/12">
            <h3 className="font-bold text-2xl leading-none mb-2">
              Mobil Food Truck
            </h3>
            <p className="leading-relaxed">
              Vi er en mobil food truck, følg os på facebook for at se, hvor vi
              kører hen. Vores retter vil løbende blive skiftet.
            </p>
          </div>

          <div className="md:w-6/12">
            <h3 className="font-bold text-2xl leading-none mb-2">
              Booking til event
            </h3>
            <p className="leading-relaxed">
              E er du på udkig efter noget nyt og spændende mad fra Asien, eller
              vi sammen kunne lave en menu til dit kommende arrangement? Vi
              bookes fra private havefester til Tinderbox 😊 kontakt os for
              nærmere info på{' '}
              <a className="text-phan" href="tel:26563581">
                26563581
              </a>{' '}
              eller{' '}
              <a className="text-phan" href="mail:mrphanhouse@gmail.com">
                mrphanhouse@gmail.com
              </a>
            </p>
          </div>
        </div>

        <FoodList title="Retter" description="*Dips skal tilkøbes">
          {PHAN_DISH.map((item) => (
            <Food
              source={
                item.image
                  ? data.food.edges.find((f) => f.node.name === item.image).node
                      .childImageSharp.fluid
                  : null
              }
              data={item}
            />
          ))}
        </FoodList>
        <FoodList
          title="Menu"
          description="Vælg mellem char siu marineret svine kød, sprød kylling, stegt kylling eller hoisin marineret pull beef."
        >
          {PHAN_MENU.map((item) => (
            <Food
              source={
                item.image
                  ? data.food.edges.find((f) => f.node.name === item.image).node
                      .childImageSharp.fluid
                  : null
              }
              data={item}
            />
          ))}
        </FoodList>
        <FoodList title="Side dish">
          {PHAN_SIDES.map((item) => (
            <Food
              source={
                item.image
                  ? data.food.edges.find((f) => f.node.name === item.image).node
                      .childImageSharp.fluid
                  : null
              }
              data={item}
            />
          ))}
        </FoodList>
      </div>
    </Layout>
  );
};

export default TakeAwayPage;
