export const VORFOR_DISH = [
  {
    name: 'Højbelagt surdejsbrød',
    description: '',
    price: '70 kr',
    image: 'vorfor-25',
  },
  {
    name: 'Kage',
    description:
      'Stort udvalg af desserter, såsom cheesecake, chokolade og tiramisu, der varierer efter årstid, sæson eller humør',
    price: '70 kr',
    image: '',
  },
  {
    name: 'Kastberg is',
    description:
      'Stort udvalg af is, der varierer efter årstid, sæson eller humør. Vælg 1x kugle 27 kr, 2 x kugler, 35 kr, 3 x kugler 45 kr',
    price: '27 kr',
    image: '',
  },
  {
    name: 'Vorfors gammeldags vaffel is',
    description:
      'Vaffel med tre valgfrie iskugler, serveret med guf, flødebolle, brunsvigersauce og en valgfri topping.',
    price: '95 kr',
    image: '',
  },
  {
    name: 'Lille softice',
    decription: '1 valgfri toppings',
    price: '26 kr',
    image: null,
  },
  {
    name: 'Mellem softice',
    descriotion: '2 valgfrie toppings',
    price: '34 kr',
    image: null,
  },
  {
    name: 'Stor softice - delevenlig',
    description: '3 valgfri toppings',
    price: '42 kr',
    image: null,
  },
];

export const VORFOR_VAFLER = [
  {
    name: 'Vaffel med Flormelis & Syltetøj',
    description: 'Vaffel med flormelis og syltetøj',
    price: '45 kr.',
    image: 'vorfor-12',
  },
  {
    name: 'Vaffel med Brunsvigersovs',
    description: 'Vaffel med brunsvigersovs',
    price: '50 kr.',
    image: 'vorfor-13',
  },
  {
    name: 'Vaffel med Nutella',
    description: 'Vaffel med nutella',
    price: '50 kr.',
    image: 'vorfor-14',
  },
  {
    name: 'Vaffel med Toppings',
    description: '3 valgfrie toppings',
    price: '55 kr.',
    image: 'vorfor-15',
  },
  {
    name: 'Vaffel med Lufttørret Skinke',
    description: 'Serveres med lufttørret skinke og parmesan',
    price: '75 kr.',
    image: 'vorfor-16',
  },
  {
    name: 'Vaffel med Laks',
    description: 'Serveres med røget laks og fynes rygeost',
    price: '75 kr.',
    image: 'vorfor-17',
  },
];

export const VORFOR_MILKSHAKES = [
  {
    name: 'Milkshake Chokolade',
    price: '50 kr.',
    image: 'vorfor-18',
  },
  {
    name: 'Milkshake Daim',
    price: '50 kr.',
    image: 'vorfor-19',
  },
  {
    name: 'Milkshake Oreo',
    price: '50 kr.',
    image: 'vorfor-20',
  },
  {
    name: 'Milkshake Vanilje',
    price: '50 kr.',
    image: 'vorfor-21',
  },
  {
    name: 'Milkshake Banan',
    price: '50 kr.',
    image: '',
  },
];

export const VORFOR_COFFEE = [
  {
    name: 'Americano',
    description: 'Vælg mellem: lille eller stor',
    price: 'fra 30 kr.',
    image: '',
  },
  {
    name: 'Cafe Latte',
    description: 'Vælg mellem: lille eller stor',
    price: 'fra 30 kr.',
    image: '',
  },
  {
    name: 'Dobbelt Espresso',
    price: '30 kr.',
    image: '',
  },
  {
    name: 'Cappuccino',
    description: 'Vælg mellem: lille eller stor',
    price: 'fra 40 kr.',
    image: '',
  },
  {
    name: 'Cortado',
    price: 'fra 40 kr.',
    image: '',
  },
  {
    name: 'Iskaffe',
    description: 'Inkl. sirup',
    price: '45 kr.',
    image: '',
  },
  {
    name: 'Chai Latte',
    price: 'fra 50 kr.',
    image: '',
  },
];

export const VORFOR_JUICE = [
  {
    name: 'Hailey',
    description: 'Appelsin, æbler, gulerod og ingefær',
    price: '55 kr.',
    image: 'vorfor-22',
  },
  {
    name: 'Milan',
    description: 'Appelsin, æbler, lime, ingefær og rødbede',
    price: '55 kr.',
    image: 'vorfor-23',
  },
  {
    name: 'Jamie',
    description: 'Æbler, bladselleri, ingefær og lime',
    price: '55 kr.',
    image: 'vorfor-24',
  },
];

export const VORFOR_BEVERAGE = [
  { name: 'Healthy smoothie', price: '54 kr', image: 'vorfor-09' },
  {
    name: 'Milkshake',
    description: 'Vælg mellem jordbær, chokolade og vanilje',
    price: '54 kr',
    image: 'vorfor-11',
  },
  {
    name: 'Signatur sovs',
    description: 'Brunsvigersauce',
    price: '5 kr',
    image: 'vorfor-10',
  },
  {
    name: 'Juice',
    description: '',
    price: '',
    image: null,
  },
  {
    name: 'Kaffe',
    description: 'Stort udvalg af barista kaffe',
    price: '',
    image: null,
  },
];
