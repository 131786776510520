import React from 'react';

import { StepOrder } from './StepOrder';

export const InfoBox = ({ phone = '26563581' }) => {
  return (
    <div>
      <div className="mb-8 text-center">
        <h3 className="font-bold text-2xl leading-none mb-2">
          Hos Mr. Phanhouse og Vorfor Vaffel kan vi nu tilbyde. Take Away.
        </h3>
        <p className="leading-relaxed text-gray-600">
          +10 kr gebyr ved take away - emballage og poser.
        </p>

        <StepOrder phone={phone} />
      </div>

      <div className="px-4 md:flex items-center justify-center mb-16 py-4 font-semibold text-center border-2 border-gray-800">
        Kunne i tænke jer at nyde vores mad i vante omgivelser. Så kan i ringe
        på tlf. <a href={`tel:${phone}`}>{phone}</a>
      </div>
    </div>
  );
};
