import React from 'react';

export const FoodList = ({ title, description, children }) => {
  return (
    <div className="mb-8">
      <div className="mb-8">
        <h2 className="font-bold text-4xl text-center sm:text-left">{title}</h2>
        <p className="text-gray-800">{description}</p>
      </div>
      <div className="grid grid-cols-1 sm:grid-cols-2 xl:grid-cols-3 gap-12">
        {children}
      </div>
    </div>
  );
};
